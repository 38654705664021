import vaultLogo from '../public/assets/vault-logo.png';
import { create } from '@storybook/theming/create';

export default create({
  fontBase: '"Ubuntu", sans-serif, "rubik", "Oswald"',
  base: 'light',
  brandTitle: 'Storybook',
  brandUrl: 'https://vaultplatform.com',
  brandImage: vaultLogo,
});
